<template>
<div id="ProfileCard">
  <div id="aCard">
    <div class="aCard__up">
      <div id="aCard__add">
        <span></span>
      </div>
    </div>
    <div class="aCard__down">
      <div>
        <p>Price</p>
        <p>1,450 €</p>
      </div>
      <div>
        <p>Views</p>
        <p>21,579</p>
      </div>
      <div>
        <p>Likes</p>
        <p>1,976</p>
      </div>
    </div>
    <div class="aCard__back">
      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores, et illo laborum quisquam reiciendis sint
        velit? <br> <br> Ad delectus dolorum eaque eius, error esse impedit incidunt iste itaque, necessitatibus officia
        voluptate?</p>
    </div>
  </div>
</div>
</template>

<script>
import { onMounted } from 'vue';

export default {
  name: 'ProfileCard',
  setup () {

    onMounted(() => {

      let card = document.getElementById('aCard');
      let button = document.getElementById('aCard__add');

      button.onclick = () => {
        card && card.classList.toggle('aCard__on');
      };

    });

  },
};
</script>

<style lang="stylus" scoped>
#ProfileCard
  position absolute
  overflow hidden
  z-index 100
  top 0
  right 0
  bottom 0
  left 0
  display flex
  align-items center
  justify-content center
  #aCard
    width 400px
    height 610px
    margin 50px auto
    border-radius 20px
    background-color #f1f7f9
    box-shadow 0 20px 70px 0 rgba(0, 0, 0, 0.21)
    position relative
    overflow hidden
    &.aCard__on
      .aCard__up
        height 100px
        box-shadow 0 0 30px #cfd8dc
        .aCard__text
          top 20px
        #aCard__add
          transform rotate(540deg) scale(0.5)
          top 490px
      .aCard__down
        height 80px
        box-shadow 0 0 30px #cfd8dc
        div
          height 40px
    .aCard__up
      position absolute
      width 100%
      height 460px
      background-image url('../../public/unsplash/_golden-ring.jpg')
      background-repeat no-repeat
      background-position center
      background-size cover
      z-index 3
      text-align center
      transition 0.5s ease-in-out
      .aCard__text
        position absolute
        top 300px
        left 0
        right 0
        color #f1f7f9
        transition 0.5s ease-in-out
        h2
          margin 0
          font-size 25px
          font-weight 600
        p
          margin 0
          font-size 16px
          color #e3f1f5
      #aCard__add
        border-radius 50%
        background-color cornflowerblue
        position absolute
        top 420px
        left 0
        right 0
        margin auto
        width 70px
        height 70px
        cursor pointer
        transition 0.5s ease-in-out
        span
          background url("../../public/icons-slider/down-arrow.svg") center / 30px no-repeat
          width 30px
          height 30px
          position absolute
          top 50%
          left 50%
          transform translate(-50%, -50%)
          filter invert()
    .aCard__down
      background-color #fff
      position absolute
      bottom 0
      width 100%
      height 150px
      z-index 2
      transition 0.5s ease-in-out
      display flex
      align-items center
      justify-content center
      div
        flex 1
        height 60px
        text-align center
        font-size 18px
        transition 0.5s ease-in-out
        display flex
        align-items center
        flex-direction column
        justify-content space-between
        p:first-of-type
          font 400 15px Averta
          color #68818c
        p:last-of-type
          font 400 20px AvertaStd
          color #334750
    .aCard__back
      text-align center
      position absolute
      top 0
      left 0
      right 0
      bottom 0
      z-index 1
      padding 30px 40px
      display flex
      align-items center
      justify-content center
      font 400 17px / 32px Averta
</style>